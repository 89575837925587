main { background-image: url(../../public/assets/imgs/header-item.png), url(../../public/assets/imgs/background.png); height: 100vh; background-position: right center, center; background-size: 95vh, cover; background-repeat: no-repeat; }
main.explorer { overflow: auto; }
main .container { height: 100%; display: flex; flex-direction: row; flex-wrap: wrap; align-content: center; justify-content: flex-start; align-items: center; }
main .container .home { text-align: left; width: 50%; }
.parent-button-container {  }
.home-button-container { display: flex; gap: 0.6rem; flex-wrap: wrap; width: 80%; justify-content: flex-start; }




section { padding: 8rem 0; }
section h2 { font-size: var(--ts-title); font-weight: 700; color: white; text-align: center; margin: 0 0 5rem 0; }
section h3 { color: var(--lightblue); text-transform: uppercase; font-weight: 700; font-size: 1.7rem; text-align: center; margin: 1.3rem 0; }


/****Supported Blockchains****/
#SupportedBlockchains { background: var(--greyblue); }
#SupportedBlockchains .sb-container { text-align: center; }
#SupportedBlockchains .sb-container .row { padding: 0 2rem; }
#SupportedBlockchains .sb-container .row .network { padding-bottom: 5rem; }
#SupportedBlockchains .sb-container .row .network img { width: 65%; display: block; margin: auto; }
#SupportedBlockchains .sb-container button { border: 1px solid rgba(241, 241, 241, 0.15); color: var(--white); background-color: var(--pink); border-radius: 5px;  font-size: 1rem;  padding: 0.4rem 0.9rem; -webkit-box-shadow: 0px 0px 15px 3px rgba(225,20,123,0.65); box-shadow: 0px 0px 10px 0px rgba(225,20,123,0.65); text-decoration: none; }
#SupportedBlockchains .sb-container button:hover { background-color: var(--darkpink); transition: 0.3s;}



/****Why Moonbridge****/
#WhyMoonbridge { background-image: url(../../public/assets/imgs/background-rotate.png); background-position: center; background-size: cover; background-repeat: no-repeat; }
#WhyMoonbridge .container p.subtitle { display: block; margin: auto; width: 60%; text-align: center; }
#WhyMoonbridge .container .row .squares { margin-top: 4rem; }
#WhyMoonbridge .container .row .squares .content { border: 1px solid var(--lightblue); border-radius: 5px; width: 100%; height: 100%; padding: 2.3rem 0; }
#WhyMoonbridge .container .row .squares .content img { width: 15%; height: auto; display: block; margin: auto; filter: var(--filter-blue); }
#WhyMoonbridge .container .row .squares .content p { color: white; text-align: center; width: 94%; margin: auto; }



/****Supported Tokens****/
#SupportedTokens { background: rgb(7,14,45); background: linear-gradient(90deg, rgba(7,14,45,1) 0%, rgba(31,39,78,1) 100%); }
#SupportedTokens .container {  }
#SupportedTokens .container .token-row { max-width: 40vw; margin: auto; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; }
#SupportedTokens .container .token-row .token { flex: 0 0 25%; margin: 0; }
#SupportedTokens .container .token-row .token img { width: 100%; height: auto; display: block; margin: auto; padding: 1rem; }





/***Large (lg)***/
@media (max-width: 1199.98px) {
    
}

/***Medium (md)***/
@media (max-width: 991.98px) {
    .home { width: 70%; }
    main .container .home { text-align: left; width: 70%; }
    main { background-image: url(../../public/assets/imgs/background.png); height: 100vh; background-position: center; background-size: cover; background-repeat: no-repeat; }
    main .container .home { text-align: center; width: 100%; }
    .parent-button-container { margin: auto; }
    .home-button-container { display: flex; gap: 0.6rem; flex-wrap: wrap; width: 80%; justify-content: center; margin: auto; }

    #SupportedBlockchains .sb-container .row { padding: 0 2rem; }
    #SupportedBlockchains .sb-container .row .network img { width: 75%; display: block; margin: auto; }

    #SupportedTokens .container .token-row { max-width: 55vw; }
    #SupportedTokens .container .token-row .token img { padding: .8rem; }

    
    
}

/***Small (sm)***/
@media (max-width: 767.98px) {
    .home-button-container { width: 100%; }
    .button-a, .button-b { width: 100%; }
    .parent-button-container{ width: 60%; }

    #SupportedTokens .container .token-row { max-width: 70vw; }
    #SupportedTokens .container .token-row .token img { padding: .5rem; }



    
}

/***Extra small (xs)***/
@media (max-width: 575.98px) {
    #SupportedBlockchains .sb-container .row .network { padding-bottom: 2.5rem; }
    #SupportedBlockchains .sb-container .row .network img { width: 65%; display: block; margin: auto; }

    #WhyMoonbridge .container p { width: 100%; }

    #SupportedTokens .container .token-row { max-width: 100vw; }
    #SupportedTokens .container .token-row .token img { padding: .5rem; }

    
}