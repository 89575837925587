.top-explorer-section{ background-image: url(/public/assets/imgs/background.png); background-size: cover; background-position: bottom; background-repeat: no-repeat; text-align: center; padding-top: 4rem; padding-bottom: 3rem; height: 220px;}
.container-searchbar{ align-content: center; width: 45%; margin-left: auto; margin-right: auto;}
.input:focus { outline: none; }
.form-control{ background-color: var(--darkblue); border-color: var(--lightblue); padding-left: 1rem; border-radius: 0.25rem 0rem 0rem 0.25rem; }
.form-control::placeholder{ font-size: var(--ts-small); color: var(--lightblue); font-size: var(--ts-sec); }
.form-control:focus{ background-color: var(--darkblue); color: var(--lightblue); font-size: var(--ts-sec); }
.btn{ background-color: var(--darkblue); border-color: var(--lightblue); border-radius: 0rem 0.25rem 0.25rem 0rem; border-left: 0; }
.btn:hover { background-color: var(--pink); transition: 0.7s; }
.btn:active { background-color: var(--darkblue); transition: 0.7s; }

/* Bottom parts */
.bottom-explorer-section{  background-color:  var(--darkblue); border-top: 1px solid var(--lightblue); text-align: center; padding-top: 1.5rem; padding-bottom: 1.5rem; }
.bottom-detail-section{ background-color:  var(--darkblue); border-top: 1px solid var(--lightblue); text-align:center; padding-top: 1.5rem; padding-bottom: 1.5rem; }
.explorer-table-div{padding-top: 1.5rem; padding-left: 7rem; padding-right: 7rem; }
.detail-table-div{  padding-left: 7rem; padding-right: 7rem; }
/* Cards parts */
.cards-div{padding-top: 1rem; padding-left: 7.75rem; padding-right: 7.75rem; }
.cards{background: rgb(7,14,45); background: linear-gradient(90deg, rgba(7,14,45,1) 0%, rgba(31,39,78,1) 100%);border: 1px solid var(--lightblue); border-radius: 0.25rem;}
.card{padding-top:1rem;padding-bottom: 1rem;  border-right: 1px solid var(--lightblue); background: none; border-radius: 0%;}
.card:nth-child(4){border-right: 0px;}
.card-content{padding-left: 1rem;text-align: left;color: var(--lightblue);}
.logo{width: 30px;}
.detail-a { color: var(--lightblue); }
.detail-a:hover { color: var(--white); transition: 0.3s;}

.explorer thead{ background-color: var(--darkblue); color: var(--lightblue); text-align: center; font-size: var(--ts-sec); }
.explorer tbody{  background-color: var(--greyblue); color: var(--white); text-align: center; font-size: var(--ts-sec); }
.explorer td{ padding: 1rem; }
.spanexplorer{ color: var(--lightblue); }
.spancard{ color: var(--white); }

table.table.explorer { margin-bottom: 0; }
.table-responsive { border-radius: 0.25rem; border: 1px solid var(--lightblue); }
.table-responsive { border-radius: 0.25rem; }


.table>:not(:first-child) { border-top: unset; }
tbody, td, tfoot, th, thead, tr { border-color: unset; border-style:unset; border-width: unset; }
tbody, td, tfoot, th, thead, tr { border-color: var(--lightblue); border-style: solid; border-width: 0; }
tbody > tr:last-child, tbody > tr:last-child td { border-bottom: none;}
table tbody tr td a { color: var(--lightblue) ; }
table tbody tr td a:hover { color: var(--white); transition: 0.3s; }

thead > tr:last-child, thead > tr:last-child td { border-bottom: none;}

thead, thead >tr:last-child {
    border: none;

}



/* TRANSACTION DETAILS */
.transaction-detail { overflow: auto; }
.top-detail-section{
    background-image: url(/public/assets/imgs/background.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    text-align: bottom;
    padding-top: 10rem;
    padding-bottom: 0rem;
    height: 220px;
}
.top-detail-subtitle{
    padding-left: 7rem;
    width: 100%;
    color: var(--lightblue);
    font-weight: var(--bold);
    font-size: var(--ts-subtitle);
}

.table tr { border-bottom:1px solid var(--lightblue) ; }
.table { margin-bottom: 0; }
.table tr:last-child { border-bottom: none; }


/* .bottom{
    padding-top: 2rem;
    padding-bottom: 4rem;
} */

.info{
    color: var(--lightblue);
    font-size: var(--ts-small);
    text-align: left;
    background-color: var(--greyblue);
}
.info td{
    padding: 1rem;
    color: var(--lightblue);
}
.infodata{
    color: var(--white);
}
/**********Large (lg)**********/
@media (max-width: 1199.98px) {}

/**********Medium (md)**********/
@media (max-width: 991.98px) {
    .explorer-table-div{
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .cards-div{padding-left: 5.75rem; padding-right: 5.75rem; }
    .detail-table-div{
        padding-left: 5rem;
        padding-right: 5rem;
    }
    /* .bottom-detail-section{
        padding-left: 6rem;
        padding-right: 6rem;
    } */
    .top-detail-subtitle{
        padding-left: 6rem;
    
    }
    .info{
        border: 0px ;
        color: var(--lightblue);
        font-size: var(--ts-small);
        text-align: left;
    }

}

/**********Small (sm)**********/
@media (max-width: 767.98px) {
    .explorer{
        border-left: 0px ;
        border-right: 0px ;
    }
    .explorer-table-div{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .cards-div{padding-left: 3.25rem; padding-right: 3.25rem; }
    .detail-table-div{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    /* .bottom-detail-section{
        padding-left: 3rem;
        padding-right: 3rem;
    } */
    .top-detail-subtitle{
        padding-left: 3rem;
    
    }
    .info{
        border: 0px ;
        color: var(--lightblue);
        font-size: var(--ts-small);
        text-align: left;
    }
  :root {
    --ts-title: 2rem;
  }
}





/**********Extra small (xs)**********/
@media (max-width: 575.98px) {
    .explorer td{
        padding: 0.75rem;
    }
    .explorer{
        border-left: 0px ;
        border-right: 0px ;
    }
    .explorer-table-div{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .cards-div{padding-left: 2.75rem; padding-right: 2.75rem; }
    .card{ border-top: 1px solid var(--lightblue);}
    .card:nth-child(2){border-right: 0px;border-top: 0px}
    .card:nth-child(1){border-top: 0px}
    .detail-table-div{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    /* .bottom-detail-section{
        padding-left: 1rem;
        padding-right: 1rem;
    } */
    .top-detail-subtitle{
        padding-left: 1rem;
    
    }
    .info{
        border: 0px ;
        color: var(--lightblue);
        font-size: var(--ts-small);
        text-align: left;
    }
  :root {
  --ts-title: 1.75rem;
  }



}