@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.explorer { overflow: auto; }

:root {
  /* Colors */
  --lightblue: #21BFDC;
  --white: #F1F1F1;
  --pink: #E1147B;
  --darkpink: #ac0e5d;
  --darkblue: #050B25;
  --greyblue: #0D1126;
  /*  Font Sizing */
  --ts-nav: 1.125rem;
  --ts-big-title: 3rem;
  --ts-title: 2.5rem;
  --ts-subtitle: 1.5rem;
  --ts-tertiary: 1.2rem;
  --ts-main: 0.8rem;
  --ts-sec: 0.875rem;
  --ts-small: 0.845rem;
  --arrow-regular: 20px;
  --arrow-small: 16px;
  --bold: 700;
  --medium: 500;
  --filter-blue: invert(85%) sepia(50%) saturate(3230%) hue-rotate(152deg) brightness(83%) contrast(109%);
  --filter-pink: invert(18%) sepia(59%) saturate(7083%) hue-rotate(320deg) brightness(91%) contrast(93%);
}

/* Text colors */
.t-white { color: var(--white); }
.t-lightblue { color: var(--lightblue); }
.t-pink { color: var(--pink);}
.t-darkblue { color: var(--darkblue); }
.t-greyblue { color: var(--greyblue); }

/* Text weight */
.tw-bold { font-weight: var(--bold); }
.tw-medium { font-weight: var(--medium); }

.t-center { text-align: center; }

/* Text size */
.ts-nav { font-size: var(--ts-nav); }
.ts-big-title { font-size: var(--ts-big-title); color: var(--lightblue); font-weight: var(--bold); }
.ts-title { font-size: var(--ts-title); color: var(--lightblue); font-weight: var(--bold); }
.ts-subtitle { font-size: var(--ts-subtitle); color: var(--lightblue); font-weight: var(--bold); }
.ts-tertiary { font-size: var(--ts-tertiary); }
.ts-main { font-size: var(--ts-main); }
.ts-sec { font-size: var(--ts-sec); }
.ts-small { font-size: var(--ts-small); }


/* Buttons */
.button-a { border: 1px solid var(--lightblue); color: var(--lightblue); background-color: transparent; border-radius: 5px; /* font-size: var(--button-text-size); */ padding: 0.4rem 0.9rem; -webkit-box-shadow: 0px 0px 5px 3px rgba(33,191,220,0.45); box-shadow: 0px 0px 5px 0px rgba(33,191,220,0.4); outline: none!important; text-decoration: none; }
.button-a:hover, .button-b:hover, .button-c:hover, .button-d:hover, .button-e:hover { color: var(--white); transition: 0.3s;}
.button-a .icon { height: var(--arrow-regular); filter: var(--filter-blue); transition: 0.5s; }
.button-b { border: 1px solid rgba(241, 241, 241, 0.15); color: var(--white); background-color: var(--pink); border-radius: 5px; /* font-size: var(--button-text-size); */ padding: 0.4rem 0.9rem; -webkit-box-shadow: 0px 0px 15px 3px rgba(225,20,123,0.65); box-shadow: 0px 0px 10px 0px rgba(225,20,123,0.65); text-decoration: none; outline: none; }
.button-b:hover { background-color: var(--darkpink); transition: 0.3s;}
.button-c { border: 1px solid var(--lightblue); color: var(--lightblue); background-color: var(--greyblue); border-radius: 5px; /* font-size: var(--button-text-size); */ padding: 0.4rem 0.9rem; text-decoration: none; }
.button-d { border: none; color: var(--white); background-color: var(--greyblue); border-radius: 5px; /* font-size: var(--button-text-size); */ padding: 0.4rem 0.9rem; text-decoration: none; }
.button-e { border: 1px solid var(--pink); color: var(--pink); background-color: var(--greyblue); border-radius: 5px; /* font-size: var(--button-text-size); */ padding: 0.4rem 0.9rem; text-decoration: none; }



/** Navbar **/
nav.navbar { color: var(--lightblue); font-size: var(--ts-nav); display: flex; justify-content: space-between; align-items: center; gap: 2rem; padding: 0.75rem 3.5rem; position: absolute; left: 0; right: 0; }
nav.navbar .moonbeam-full { width: 10rem; height: auto; z-index: 10; }
nav .nav-items { display: flex; justify-content: flex-end; align-items: center; align-content: center; flex-wrap: wrap; gap: 2rem; flex-direction: row; }
nav .nav-items ul { padding: 0; margin: 0; list-style: none; display: flex; gap: 2rem; align-items: center; flex-direction: row; }
nav .nav-items ul li a { color: inherit; text-decoration: none; display: flex; align-items: center; gap: .25rem; transition: 0.5s; }
nav .nav-items ul li a .icon { height: var(--arrow-regular); filter: var(--filter-blue); -webkit-transform:rotate(270deg); -moz-transform: rotate(270deg); -ms-transform: rotate(270deg); -o-transform: rotate(270deg); transform: rotate(270deg); transition: 0.5s; }
nav .nav-items ul li a:hover { color: var(--white); text-decoration: none; }
nav .nav-items ul li a:hover .icon { filter: invert(500%); }
nav .nav-items ul li a.active {  }
nav .nav-items ul li a.inactive {  }

/*Nav toggler*/
#nav-toggler { padding-top: .25rem; padding-bottom: .25rem; transition: 0.4s; border-radius: 5px; padding-left: 6px; padding-right: 6px; display: none; z-index: 1; }
#nav-toggler .bar1, .bar2, .bar3 { width: 30px; height: 3px; border-radius: 10rem; background-color: var(--lightblue); margin: 6px 0; transition: 0.5s; }
#nav-toggler.open .bar1 { -webkit-transform: rotate(-45deg) translate(-9px, 6px); transform: rotate(-45deg) translate(-9px, 6px); }
#nav-toggler.open .bar2 { opacity: 0; }
#nav-toggler.open .bar3 { -webkit-transform: rotate(45deg) translate(-6px, -4px); transform: rotate(45deg) translate(-6px, -4px); }
#nav-toggler:hover :is(.bar1, .bar2, .bar3) { background-color: var(--white); }

/*Navbar account*/
nav .account { z-index: 1; }
nav .account .account.dropdown {  }
nav .account .account.dropdown .button-a { z-index: 1; }
nav .account .account.dropdown .dropdown-menu { display: none; z-index: 2; }
nav .account .account.dropdown:hover {  }
nav .account .account.dropdown:hover .button-a {  }
nav .account .account.dropdown:hover .button-a .icon { transform: rotate(180deg) translateY(1px); }
nav .account .account.dropdown:hover .dropdown-menu { display: block; margin: 0; background: transparent; min-width: unset; font-size: inherit; left: 15%; right: 0; }
nav .account .account.dropdown .dropdown-menu a { color: var(--pink); justify-content: center; text-align: center; display: block; border: 1px solid var(--pink); border-radius: 5px; padding: 0.4rem 0; text-decoration: none; }


/****Footer****/

footer { background-color: var(--greyblue); padding: 3rem 0; }
footer img { width: 15%; display: block; margin-bottom: .5rem; }
footer p { color: white; font-size: .8rem; }



/**********Large (lg)**********/
@media (max-width: 1199.98px) {}

/**********Medium (md)**********/
@media (max-width: 991.98px) {
  .navbar.open { background: var(--greyblue); }
  nav .logo { order: 1; }
  nav .nav-items { order: 2; }
  nav .nav-items ul { position: absolute; top: 0; left: 0; right: 0; padding: 1rem 1.5rem 1.75rem 1.5rem; margin: 4rem 0 0 0; list-style: none; display: flex; gap: 1rem; align-items: flex-start; flex-direction: column; background-color: var(--greyblue); transition: 0s; transform: translateY(-100vh); z-index: 1; }
  nav.open .nav-items ul { transform: translateY(0); }
  
  #nav-toggler { display: inline-block; position: relative; }

}

/**********Small (sm)**********/
@media (max-width: 767.98px) {
  :root {
    --ts-nav: 1rem;
    --ts-big-title: 2.5rem;
    --ts-title: 2rem;
    --ts-subtitle: 1.4rem;
    --ts-tertiary: 1.1rem;
    --ts-main: 0.8rem;
    --ts-sec: 0.875rem;
    --ts-small: 0.845rem;
  }
  footer img { width: 40%; }
}





/**********Extra small (xs)**********/
@media (max-width: 575.98px) {
  .button-a { padding: 0.25rem 0.7rem; }

  nav.navbar { padding: 0.75rem 1.5rem; font-size: 1rem; gap: 0; }
  nav.navbar .moonbeam-full { width: 7.5rem; height: auto; z-index: 10; }
  nav .nav-items { gap: 1rem; }

  :root {
    --ts-nav: 1rem;
  --ts-big-title: 2rem;
  --ts-title: 1.75rem;
  --ts-subtitle: 1.3rem;
  --ts-tertiary: 1.1rem;
  --ts-main: 0.8rem;
  --ts-sec: 0.875rem;
  --ts-small: 0.845rem;  
  }



}